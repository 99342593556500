import React from "react";
import {
  Hidden,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      backgroundColor: "transparent",
      padding: theme.spacing(2.5),
    },
    setpRoot: {
      "&:nth-child(2)": {
        "& $line": {
          borderRadius: "2px 0 0 2px",
        },
      },
      "&:last-child": {
        "& $line": {
          borderRadius: "0 2px 2px 0",
        },
      },
    },
    lineAlternativeLabel: {
      top: 18,
      left: "calc(-50% + 22px)",
      right: "calc(50% + 22px)",
    },
    line: {
      borderColor: "#82ACA2",
      borderTopWidth: 3,
    },
    lineActive: {
      "& $line": {
        borderColor: theme.palette.success.main,
      },
    },
    lineCompleted: {
      "& $line": {
        borderColor: theme.palette.success.main,
      },
    },
    setpButton: {
      padding: 0,
      margin: 0,
      position: "relative",
      paddingTop: theme.spacing(4),
    },
    iconContainer: {
      position: "absolute",
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 40,
      height: 40,
    },
    iconRoot: {
      color: "transparent",
      border: `2px solid ${theme.palette.background.default}`,
      boxShadow: "0px 1px 7px rgba(0,0,0,0.2)",
      borderRadius: "50%",
      height: 30,
      width: 30,
      paddingTop: 2,
      boxSizing: "border-box",
      "& svg": {
        height: "100%",
        width: "100%",
        color: "transparent",
      },
      "& text": {
        fill: theme.palette.success.main,
        fontSize: "16px",
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: "normal",
      },
      "&$iconActive": {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.main,
      },
      "&$iconCompleted": {
        color: theme.palette.success.main,
        paddingTop: 0,
      },
    },
    iconActive: {
      height: 40,
      width: 40,
      border: `3px solid ${theme.palette.background.default}`,
      "& text": {
        fill: theme.palette.primary.dark,
      },
    },
    labelContainer: {
      "& $labelRoot": {
        marginTop: theme.spacing(0.6),
        color: theme.palette.primary.contrastText,
      },
      "& $labelActive": {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: "normal",
      },
    },
    // without these empty objects, you get warnings:
    iconCompleted: {},
    labelRoot: {},
    labelActive: {},
    disabled: {},
    mobile: {
      textAlign: "center",
      paddingBottom: theme.spacing(1),
      "& p": { color: theme.palette.common.white },
    },
  }))();

export type FHStep = {
  label: JSX.Element | string;
  completed?: boolean;
  url?: string;
  disableClick?: boolean;
};
export type FHStepperProps = {
  steps: FHStep[];
  activeStepIndex: number;
  onStepClick?: (url: string) => void;
  disableSteps?: boolean;
};

const defaultProps: FHStepperProps = Object.assign({
  steps: [],
  activeStepIndex: 0,
});

export const FHStepper = ({
  steps,
  activeStepIndex = 0,
  onStepClick,
  disableSteps,
}: FHStepperProps = defaultProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobileActiveStep: number = activeStepIndex + 1;

  return (
    <>
      <Hidden mdDown>
        <Stepper
          alternativeLabel
          nonLinear
          activeStep={activeStepIndex}
          className={classes.root}
          connector={
            <StepConnector
              classes={{
                alternativeLabel: classes.lineAlternativeLabel,
                line: classes.line,
                active: classes.lineActive,
                completed: classes.lineCompleted,
              }}
            />
          }
        >
          {steps.map(({ label, completed, url, disableClick }, index) => (
            <Step
              key={"label" + index}
              completed={completed}
              className={classes.setpRoot}
            >
              <StepButton
                className={classes.setpButton}
                onClick={
                  disableClick
                    ? undefined
                    : onStepClick && url
                    ? () => onStepClick(url)
                    : undefined
                }
                disableTouchRipple
                disabled={
                  disableSteps ||
                  (!disableClick && onStepClick
                    ? index >= activeStepIndex
                    : true)
                }
              >
                <StepLabel
                  classes={{
                    iconContainer: classes.iconContainer,
                    labelContainer: classes.labelContainer,
                    label: classes.labelRoot,
                    active: classes.labelActive,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.iconRoot,
                      active: classes.iconActive,
                      completed: classes.iconCompleted,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <Hidden mdUp>
        {!!mobileActiveStep && !!steps?.length && (
          <div className={classes.mobile}>
            <Typography>{`Step ${mobileActiveStep} of ${steps.length}`}</Typography>
          </div>
        )}
      </Hidden>
    </>
  );
};
